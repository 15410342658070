import Vue from 'vue'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    currentQuery: null,
    convo: {
      access: [],
    },
    convos: [],
    types: [],
    sections: {},
    loading: false,
    sended: false,
    applicants: [],
    applicantsTotal: 0,
    evaluators: [],
    evaluations: [],
    rankeds: [],
    evaluator: {},
    totalConvos: 0,
    totalRankeds: 0,
    totalUsers: 0,
    evaluatorsTotal: 0,
    pendingApplicants: 0,
    evaluatorConvoMessage: null,
    rankedArea: null,
    clickedTab: 0,
    pdfEval: [],
    promotionStats: {},
    rankedEvaluators: [],
    promoEvaluation: {},
    filters: {
      candidate: null,
      areas: {},
    },
    promoResearchers: [],
    promoPcms: [],
    totalSeniorConvos: 0,
    seniorConvo: {},
    seniorConvos: [],
    totalAcademyConvos: 0,
    academyConvo: {},
    academyConvos: [],
  },
  getters: {
    promoResearchers: state => state.promoResearchers,
    totalRankeds: state => state.totalRankeds,
    totalUsers: state => state.totalUsers,
    loading: state => state.loading,
    convo: state => state.convo,
    sections: state => state.sections,
    sended: state => state.sended,
    convos: state => state.convos,
    filters: state => state.filters,
    totalConvos: state => state.totalConvos,
    totalSeniorConvos: state => state.totalSeniorConvos,
    seniorConvo: state => state.seniorConvo,
    seniorConvos: state => state.seniorConvos,
    totalAcademyConvos: state => state.totalAcademyConvos,
    academyConvo: state => state.academyConvo,
    academyConvos: state => state.academyConvos,
    types: state => state.types,
    applicants: state => state.applicants,
    applicantsTotal: state => state.applicantsTotal,
    evaluators: state => state.evaluators,
    evaluator: state => state.evaluator,
    evaluations: state => state.evaluations,
    rankedArea: state => state.rankedArea,
    rankedEvaluators: state => state.rankedEvaluators,
    pdfEval: state => state.pdfEval,
    evaluatorsTotal: state => state.evaluatorsTotal,
    evaluatorConvoMessage: state => state.evaluatorConvoMessage,
    promotionStats: state => state.promotionStats,
    pendingApplicants: state => state.pendingApplicants,
    clickedTab: state => state.clickedTab,
    promoEvaluation: state => state.promoEvaluation,
    promoPcms: state => state.promoPcms,
    rankeds: state => state.rankeds.sort((a, b) => {
      if (a.rank === null && b.rank !== null) {
        return 1 // Move null values to the end
      } if (a.rank !== null && b.rank === null) {
        return -1 // Keep null values at the end
      } if (a.rank === null && b.rank === null) {
        return 0 // Leave null values in their current order
      }
      return a.rank - b.rank // Compare non-null values
    }),
  },
  mutations: {
    SAVE_PROMO_PCMS(state, promoPcms) {
      state.promoPcms = promoPcms
    },
    SAVE_PROMO_RESEARCHERS(state, promoResearchers) {
      state.promoResearchers = promoResearchers
    },
    SAVE_CURRENT_QUERY(state, currentQuery) {
      state.currentQuery = currentQuery
    },
    SAVE_CLICKED_TAB(state, clickedTab) {
      state.clickedTab = clickedTab
    },
    SAVE_EVALUATORS(state, evaluators) {
      state.evaluators = evaluators
    },
    SAVE_APPLICANTS(state, applicants) {
      state.applicants = applicants
    },
    SAVE_TYPES(state, types) {
      state.types = types
    },
    SAVE_CONVO(state, convo) {
      state.convo = convo
    },
    SAVE_CONVOS(state, convos) {
      state.convos = convos
    },
    SAVE_SECTIONS(state, sections) {
      state.sections = sections
    },
    SAVE_FILTERS(state, filters) {
      state.filters = filters
    },
    TOGGLE_LOADING(state) {
      state.loading = !state.loading
    },
    FETCH_FAILED(state) {
      // state.convo = {}
    },
    FETCH_FAILED_CONVOS(state) {
      state.convos = {}
      state.totalConvos = 0
    },
    SAVE_CONVO_TOTAL(state, total) {
      state.totalConvos = total
    },
    SAVE_SENIOR_TOTAL(state, total) {
      state.totalSeniorConvos = total
    },
    SAVE_SENIOR(state, convo) {
      state.seniorConvo = convo
    },
    SAVE_SENIORS(state, convos) {
      state.seniorConvos = convos
    },
    SAVE_ACADEMY_TOTAL(state, total) {
      state.totalAcademyConvos = total
    },
    SAVE_ACADEMY(state, convo) {
      state.academyConvo = convo
    },
    SAVE_ACADEMYS(state, convos) {
      state.academyConvos = convos
    },
    SENDED_CONVO(state, status) {
      try {
        if (status.status) {
          state.sended = true
        } else {
          state.sended = false
        }
      } catch (e) {
        state.sended = false
      }
    },
    ERROR_SENDING_CONVO(state) {
      state.sended = false
    },
    SAVE_EVALUATOR(state, evaluator) {
      state.evaluator = evaluator
    },
    SAVE_EVALUATIONS(state, evaluations) {
      state.evaluations = evaluations
    },
    SAVE_RANKEDS(state, rankeds) {
      state.rankeds = rankeds
    },
    SAVE_TOTAL_RANKEDS(state, total) {
      state.totalRankeds = total
    },
    SAVE_TOTAL_USER(state, total) {
      state.totalUsers = total
    },
    SAVE_RANKED_AREA(state, rankedArea) {
      state.rankedArea = rankedArea
    },
    SAVE_RANKED_EVALUATORS(state, rankedEvaluators) {
      state.rankedEvaluators = rankedEvaluators
    },
    SAVE_PDF_EVAL(state, pdfEval) {
      state.pdfEval = pdfEval
    },
    SAVE_APPLICANT_TOTAL(state, total) {
      state.applicantsTotal = total
    },
    SAVE_EVALUATORS_TOTAL(state, total) {
      state.evaluatorsTotal = total
    },
    SAVE_EVALUATOR_MESSAGE(state, msg) {
      state.evaluatorConvoMessage = msg
    },
    SAVE_PROMOTION_STATS(state, data) {
      state.promotionStats = data
    },
    SAVE_PENDING_APPLICANTS(state, applicants) {
      state.pendingApplicants = applicants
    },
    SAVE_PROMO_EVALUATION(state, evaluation) {
      state.promoEvaluation = evaluation
    },
  },
  actions: {
    async fetchPromotionStats({ commit }, convoId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${convoId}/promotion-stats`)
        if (resp.status === 200) {
          commit('SAVE_PROMOTION_STATS', resp.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchPdfEvaluators({ commit }, convoId) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${convoId}/get-areas-evaluators`)
      commit('SAVE_PDF_EVAL', resp.data)
    },
    async setRank({ commit, state }, data) {
      try {
        const { rankeds } = state
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${state.convo.id}/set-rank`, data)
        if (resp.status === 200) {
          setTimeout(() => {
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 100)

          rankeds[rankeds.findIndex(x => x.user_id === data.item.user_id)].rank = data.rank
          commit('SAVE_RANKEDS', rankeds)
          return 0
        }

        return 1
      } catch (e) {
        return 1
      }
    },
    async fetchPromoResearchers({ commit }, data) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${data.convo_id}/get-promotion-ranking?area=${data.area_id}`)
      if (resp.status === 200) {
        commit('SAVE_PROMO_RESEARCHERS', resp.data.researchers)
        commit('SAVE_TOTAL_RANKEDS', resp.data.total)
        commit('SAVE_PROMO_PCMS', resp.data.pcms)
      }
    },
    async fetchRankeds({ commit, state }, data) {
      commit('SAVE_RANKEDS', [])
      if (data.area) {
        commit('SAVE_RANKED_AREA', data.area)
      }
      const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${state.convo.id}/get-rankeds`, {
        area: state.rankedArea,
        all: data.all ? 1 : null,
        reorder: data.reorder,
        dir: data.dir,
      })
      if (resp.status === 200) {
        commit('SAVE_RANKED_EVALUATORS', resp.data.evaluators)
        commit('SAVE_RANKEDS', resp.data.rankeds.data)
        commit('SAVE_TOTAL_RANKEDS', resp.data.rankeds.totalRankeds)
        commit('SAVE_TOTAL_USER', resp.data.rankeds.total)
      }
    },
    async fetchAll({ commit }, type) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/filter`, {
          type,
        })
        if (resp.status === 200) {
          commit('SAVE_CONVOS', resp.data.data.data)
          // commit('SAVE_CONVO', resp.data.active)
          commit('SAVE_CONVO_TOTAL', resp.data.total)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchAcademy({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/filter`, {
          type: 'academy',
          ...filters,
        })
        if (resp.status === 200) {
          commit('SAVE_ACADEMYS', resp.data.data.data)
          commit('SAVE_ACADEMY', resp.data.active)
          commit('SAVE_ACADEMY_TOTAL', resp.data.total)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchPromotion({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/filter`, {
          type: 'promotion',
          ...filters,
        })
        if (resp.status === 200) {
          commit('SAVE_CONVOS', resp.data.data.data)
          commit('SAVE_CONVO', resp.data.active)
          commit('SAVE_CONVO_TOTAL', resp.data.total)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchSenior({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/filter`, {
          type: 'senior',
          ...filters,
        })
        if (resp.status === 200) {
          commit('SAVE_SENIORS', resp.data.data.data)
          commit('SAVE_SENIOR', resp.data.active)
          commit('SAVE_SENIOR_TOTAL', resp.data.total)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchTypes({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo-types`)
        if (resp.status === 200) {
          commit('SAVE_TYPES', resp.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/get-active-senior`)
        if (resp.status === 200) {
          commit('SAVE_SECTIONS', [])
          commit('SAVE_CONVO', resp.data.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async academyFetch({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/get-active-academy`)
        if (resp.status === 200) {
          commit('SAVE_SECTIONS', [])
          commit('SAVE_CONVO', resp.data.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchSpecific({ commit }, convoId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${convoId}`)
        if (resp.status === 200) {
          if (window.location.href.includes('/intranet')) {
            resp.data.data.open_applicants = true
          }

          commit('SAVE_CONVO', resp.data.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchSections({ commit }, convoId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${convoId}/sections`)
        if (resp.status === 200) {
          commit('SAVE_SECTIONS', resp.data.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchUserSections({ commit }, [convoId, userId]) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${convoId}/sections?user=${userId}`)
        if (resp.status === 200) {
          commit('SAVE_SECTIONS', resp.data.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async sendConvo({ commit }, convo) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/active-senior/send`, { convo })
        if (resp.status === 200) {
          commit('SENDED_CONVO', resp.data)
        } else {
          commit('ERROR_SENDING_CONVO')
        }
      } catch (e) {
        commit('ERROR_SENDING_CONVO')
      }
    },
    removeConvo({ commit }) {
      commit('SAVE_CONVO', {})
    },
    addConvo({ commit }, convo) {
      commit('SAVE_CONVO', convo)
    },
    async update({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.put(`${Vue.prototype.$groupUrl}/convo/${data.convoId}`, data.form)
        if (resp.status === 200) {
          if (data.form.data['files-1']) {
            Vue.prototype.$saveFiles(data.form.data['files-1'])
          }
          if (data.form.data['files-2']) {
            Vue.prototype.$saveFiles(data.form.data['files-2'])
          }
          if (data.form.data['files-3']) {
            Vue.prototype.$saveFiles(data.form.data['files-3'])
          }
          commit('SAVE_CONVO', resp.data.data)
          commit('SENDED_CONVO', { status: true })
        } else {
          commit('ERROR_SENDING_CONVO')
        }
      } catch (e) {
        commit('ERROR_SENDING_CONVO')
      }
    },
    async create({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo`, data.form)
        if (resp.status < 300) {
          if (data.form.data['files-1']) {
            Vue.prototype.$saveFiles(data.form.data['files-1'])
          }
          if (data.form.data['files-2']) {
            Vue.prototype.$saveFiles(data.form.data['files-2'])
          }
          if (data.form.data['files-3']) {
            Vue.prototype.$saveFiles(data.form.data['files-3'])
          }
          commit('SAVE_CONVO', resp.data.data)
        } else {
          commit('ERROR_SENDING_CONVO')
        }
      } catch (e) {
        commit('ERROR_SENDING_CONVO')
      }
    },
    async filter({ commit }, filters) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/filter`, filters)
        if (resp.status < 300) {
          commit('SAVE_CONVOS', resp.data.data.data)
          commit('SAVE_CONVO_TOTAL', resp.data.total)
          commit('SAVE_FILTERS', filters)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    export({ commit, state }) {
      try {
        Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/export`, state.filters).then(response => {
          window.open(response.data)
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    async duplicate({ commit }, id) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/duplicate/${id}`)
        if (resp.status < 300) {
          Vue.$toast.success('Duplicated correctly')
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    reorderApplicants({ commit, state }, order) {
      const { applicants } = state

      applicants.sort((a, b) => {
        if (order) {
          if (a.full_name > b.full_name) {
            return -1
          }
          if (a.full_name < b.full_name) {
            return 1
          }
          return 0
        }

        if (a.full_name < b.full_name) {
          return -1
        }
        if (a.full_name > b.full_name) {
          return 1
        }
        return 0
      })

      commit('SAVE_APPLICANTS', applicants)
    },
    async fecthApplicantsByEval({ commit }, data = null) {
      const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${data.id}/get-applicants-by-eval/${data.user_id}`)
      if (resp.status < 300) {
        const applicants = Object.keys(resp.data.data).map(e => {
          resp.data.data[e] = { ...resp.data.data[e], full_name: `${resp.data.data[e].last_name_1 ? resp.data.data[e].last_name_1 : ''} ${resp.data.data[e].last_name_2 ? resp.data.data[e].last_name_2 : ''}, ${resp.data.data[e].first_name}` }
          return resp.data.data[e]
        })
        commit('SAVE_APPLICANTS', applicants)
        commit('SAVE_APPLICANT_TOTAL', resp.data.total)
      } else {
        commit('FETCH_FAILED')
      }
    },
    async fetchApplicants({ commit, state }, data = {}) {
      if (state.currentQuery) {
        state.currentQuery.cancel('Request canceled by user')
      }

      commit('SAVE_CURRENT_QUERY', axios.CancelToken.source())
      commit('SAVE_APPLICANTS', [])
      try {
        let id = 0
        let status = null
        if (!data.id) {
          id = state.convo.id
        } else {
          id = data.id
        }

        if (!data.status) {
          status = state.clickedTab
        } else if (data.status.hasOwnProperty('value')) {
          status = data.status.value
        } else {
          status = data.status
        }

        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${id}/get-applicants/${status}`, data, { cancelToken: state.currentQuery.token })
        if (resp.status < 300) {
          const applicants = Object.keys(resp.data.data).map(e => {
            resp.data.data[e] = {
              ...resp.data.data[e],
              full_name: `${resp.data.data[e].last_name_1 ? resp.data.data[e].last_name_1 : ''} ${resp.data.data[e].last_name_2 ? resp.data.data[e].last_name_2 : ''}, ${resp.data.data[e].first_name}`,
              name: `${resp.data.data[e].last_name_1 ? resp.data.data[e].last_name_1 : ''} ${resp.data.data[e].last_name_2 ? resp.data.data[e].last_name_2 : ''}, ${resp.data.data[e].first_name}`,
            }
            return resp.data.data[e]
          })
          setTimeout(() => {
            // eslint-disable-next-line no-undef
            feather.replace({
              width: 14,
              height: 14,
            })
          }, 100)
          commit('SAVE_APPLICANTS', applicants)
          commit('SAVE_APPLICANT_TOTAL', resp.data.total)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchEvaluators({ commit, state }, convoId) {
      try {
        let id = 0
        if (!Number.isInteger(convoId)) {
          id = state.convo.id
        } else {
          id = convoId
        }
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${id}/get-evaluators`)
        if (resp.status < 300) {
          commit('SAVE_EVALUATORS', resp.data.data)
          commit('SAVE_EVALUATORS_TOTAL', resp.data.total)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchPendingEvaluators({ commit, state }, convoId) {
      try {
        let id = 0
        if (!Number.isInteger(convoId)) {
          id = state.convo.id
        } else {
          id = convoId
        }
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${id}/get-pending-evaluators`)
        if (resp.status < 300) {
          commit('SAVE_EVALUATORS', resp.data.data)
          commit('SAVE_EVALUATORS_TOTAL', resp.data.total)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchEvaluator({ commit }, evalId) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/get-evaluator-data/${evalId}`)
        if (resp.status < 300) {
          commit('SAVE_EVALUATOR', resp.data.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async assignEvaluators({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${data.convoId}/set-evaluators`, data)
        if (resp.status < 300) {
          commit('SAVE_EVALUATORS', resp.data.data)
          commit('SAVE_EVALUATORS_TOTAL', resp.data.data.length)
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async assignAppl({ commit, dispatch }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${data.convoId}/set-applicants`, data)
        await dispatch('fetchApplicants', { id: data.convoId, status: null })
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async filterEvaluators({ commit, state }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${state.convo.id}/filter-evaluators`, data)
        if (resp.status < 300) {
          commit('SAVE_EVALUATORS', resp.data.data)
          commit('SAVE_EVALUATORS_TOTAL', resp.data.total)
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async filterEvaluatorsPivot({ commit, state }, data) {
      try {
        const convoId = state.convo.id ? state.convo.id : data.convoId
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${convoId}/filter-evaluators-pivot`, data)
        if (resp.status < 300) {
          commit('SAVE_EVALUATORS', resp.data.data)
          commit('SAVE_EVALUATORS_TOTAL', resp.data.total)
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async assignApplicants({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${data.convoId}/set-evaluators-applicants`, data)
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async assignFeedback({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${data.convoId}/set-applicants-feedback`, data)
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchAssignedConvo({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/get-assigned-convo`)
        if (resp.data.data) {
          commit('SAVE_CONVO', resp.data.data)
        } else {
          commit('SAVE_EVALUATOR_MESSAGE', resp.data)
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async syncronizeData({ commit }, data) {
      try {
        await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${data.id}/syncronize-data`)
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async fetchEvaluations({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${data.convoId}/get-evaluations/${data.userId}`)
        if (resp.status === 200) {
          commit('SAVE_EVALUATIONS', resp.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async downloadZipApplicants({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/export-applicants`, data, {
          responseType: 'arraybuffer',
        }).then(response => {
          if (!data.background) {
            // Create a Blob from the received response data
            const blobData = new Blob([response.data], { type: 'octet/stream' })

            // Create a URL for the blob data
            const blobUrl = window.URL.createObjectURL(blobData)

            // Create a link and initiate the download
            const link = document.createElement('a')
            link.href = blobUrl
            link.download = 'applicants.zip'
            link.click()
          } else if (response.status == 200) {
            Vue.swal('Export started', 'You will receive an email when its done', 'success')
          } else {
            Vue.swal('Export failed', 'Please, contact to the administrator', 'error')
          }

          Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/cleanup`)
        })
      } catch (e) {
        console.log(e)
      }
    },
    exportApplicants({ commit, state }) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/convo/${state.convo.id}/export-applicants`,
          filters: state.filters,
          name: 'users',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    exportCallEvaluators({ commit, state }) {
      try {
        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/convo/${state.convo.id}/export-evaluators`,
          filters: state.filters,
          name: 'evaluators',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    exportApplicantsRound0({ commit, state }) {
      try {
        const { filters } = state
        filters.status = state.clickedTab

        Vue.prototype.$export({
          url: `${Vue.prototype.$groupUrl}/convo/${state.convo.id}/export-applicants-round-0`,
          filters: state.filters,
          name: 'avaluations',
        })
      } catch (e) {
        console.log(e)
        commit('FETCH_FAILED')
      }
    },
    // eslint-disable-next-line no-unused-vars
    async sendEvaluatorCredentials({ commit }, data) {
      try {
        await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${data.convoId}/send/evaluator-credentials`, data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    // ----------- PROMOTION ----------- //
    async promotionReport({ commit, state }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${state.convo.id}/promotion-report`, data)
        commit('SAVE_APPLICANTS', resp.data.data)
        commit('SAVE_APPLICANT_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async promotionIcreas({ commit, state }, data) {
      try {
        const info = { ...data }
        if (localStorage.getItem('currentRoute') && localStorage.getItem('currentRoute').includes('panel-meeting')) {
          info.hideEvaluation = true
        }

        commit('SAVE_APPLICANTS', [])
        const filters = { ...info, icreas: true }
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${state.convo.id}/promotion-report`, filters)
        commit('SAVE_APPLICANTS', resp.data.data)
        commit('SAVE_APPLICANT_TOTAL', resp.data.total)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async getActivePromotion({ commit }) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/get-promotion-active`)
        commit('SAVE_CONVO', resp.data)
      } catch (e) {
        console.log(e)
        throw e
      }
    },
    async fetchEvaluatorPendingApplicants({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${data.convoId}/get-evaluations-pending-applicants/${data.user}/${data.round}`)
        if (resp.status === 200) {
          commit('SAVE_PENDING_APPLICANTS', resp.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    saveClickedTab({ commit }, clickedTab) {
      commit('SAVE_CLICKED_TAB', clickedTab)
    },
    async fetchPromoEvaluation({ commit }, data) {
      try {
        const resp = await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/get-evaluation`, data)
        if (resp.status === 200) {
          commit('SAVE_PROMO_EVALUATION', resp.data)
        } else {
          commit('FETCH_FAILED')
        }
      } catch (e) {
        commit('FETCH_FAILED')
      }
    },
    async clearPromoEvaluation({ commit }) {
      commit('SAVE_PROMO_EVALUATION', {})
    },
  },
}
